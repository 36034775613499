@use "./config/" as *;
@forward "../scss/main-scss/";
@forward "../scss/listing/multimedia-common-listing";
.search-section {
    .search-results {
        &-modal {
            box-shadow: 0 0.2rem 1.5rem 0 rgba(0, 0, 0, 0.15);
        }
        &-item {
            .btn-text::before {
                color: var(--neutral-100);
                @include icon(close, 24);
            }
        }
    }
}
.waf-listing {
    .news-page, .videos-page, .photos-page {
        .head-wrap {
            margin-block: 10.5rem 15.5rem;
        }
    }
}
@include mq(col-tablet) {
    .waf-listing {
        .news-page, .videos-page, .photos-page {
            .head-wrap {
                margin-block: 23.3rem 15rem;
            }
        }
    }
}