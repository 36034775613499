@use "../config/" as *;
$icon-class: (
    news: \e837,
    videos: \e83d,
    photos: \e839
);
// @each $class,
// $icon in $icon-class {
//     .#{$class}-page {
//         .waf-listing {
//             .waf-head {
//                 .title::before {
//                     content: "#{$icon}";
//                 }
//             }
//         }
//     }
// }
.search-section {
    .search-results-modal {
        width: 100%;
        padding-top: 10.4rem;
        @include position(0);
    }
    .btn-search[aria-expanded=true] {
        @extend %zindex-search-button;
        @include position(3.6rem, 2.4rem);
        .btn-text::before {
            @include icon(close, 24);
        }
    }
    .no-result {
        font-weight: 700;
    }
    .search-results-content {
        @extend %pt-6;
    }
}
.waf-listing {
    .waf-head {
        height: 35rem;
    }
    @extend %page-head-blue-bg;
    &.waf-component {
        padding-block: 0 var(--space-20);
        @extend %mb-0;
    }
    // .waf-head {
    //     .title {
    //         @include title-with-icon(shape, megaphone-fill, 21, neutral-0);
    //     }
    // }
    .waf-body {
        margin-top: -8.9rem;
        @extend %bg-transparent;
    }
    .priamry-tabs {
        border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
        border-bottom: 0.1rem solid var(--neutral-20);
        @extend %pt-6;
        @extend %bg-neutral-0;
        @extend %flex-n-c;
        @extend %x-auto;
        .tabs {
            width: calc(100% - 4.8rem);
        }
    }
    .secondary-tabs {
        border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
        @extend %bg-neutral-0;
        @extend %py-6;
        .tab {
            &-text {
                font-weight: 700;
            }
            &-item.active {
                border: 0.2rem solid var(--neutral-20);
                border-radius: var(--border-radius-xl);
                @include bg(neutral-20, 3);
                .tab-name {
                    &::before {
                        content: unset;
                    }
                }
            }
        }
    }
    .search-result-count {
        border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
        @extend %bg-neutral-0;
        @extend %p-6-4;
    }
    .search-no-result {
        @extend %my-0;
    }
    .tab {
        &s {
            @extend %x-auto;
        }
        &-container {
            @extend %mt-8;
            @extend %bg-neutral-0;
            @extend %border-radius-m;
            @extend %p-8-4;
        }
        &-name {
            width: max-content;
        }
    }
    .search-section {
        width: 4.8rem;
        aspect-ratio: 1/1;
        @extend %flex-c-c;
        .btn-text {
            @extend %font-0;
            &::before {
                @include icon(search, 24);
                @extend %primary-50;
            }
        }
    }
    .title {
        @extend %title-30;
        @extend %neutral-0;
    }
    &.search-only {
        .search-section {
            @extend %w-100;
            aspect-ratio: unset;
            .site-search-form {
                .search-input {
                    @extend %py-6;
                }
            }
            .btn-text {
                &::before {
                    @extend %d-none;
                }
            }
        }
        .search-results-modal {
            position: unset;
            @extend %d-block;
            @extend %p-0;
        }
    }
    @extend %tabs-underline;
}
.waf-overlay-list {
    .article-list {
        flex-direction: column;
    }
}
.waf-listing.waf-horizontal-list {
    .article-title {
        margin-bottom: 0;
        height: unset;
    }
}
.media-listing-page {
    .waf-horizontal-list {
        @include article-listing(card);
    }
    .waf-head {
        &::before {
            top: 10rem;
        }
    }
    .waf-listing {
        .title {
            &::before {
                content: unset;
            }
        }
        .search {
            &-section {
                aspect-ratio: unset;
                @extend %w-100;
            }
            &-results-modal {
                top: unset;
                border-bottom-left-radius: unset;
                border-bottom-right-radius: unset;
                @extend %w-100;
                @extend %relative;
                @extend %d-block;
                @extend %pb-0;
            }
        }
        .tab-container {
            border-top-left-radius: unset;
            border-top-right-radius: unset;
            @extend %mt-0;
        }
    }
}
@include mq(col-md) {
    .waf-listing {
        .waf-head {
            height: 45rem;
        }
    }
}
@include mq(col-lg) {
    .search-section {
        .btn-search[aria-expanded=true] {
            right: 8rem;
        }
        .no-result {
            font-size: 1.6rem;
        }
        .search-results-content {
            padding-top: var(--space-8);
        }
    }
    .waf-listing {
        .tab {
            &-container {
                border-radius: var(--border-radius-xl);
                padding: var(--space-14) var(--space-10);
            }
        }
        .priamry-tabs {
            border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
        }
        .secondary-tabs {
            border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
            .tab-name {
                height: 4.6rem;
            }
        }
        .priamry-tabs,
        .secondary-tabs {
            padding-inline: var(--space-10);
            .tabs {
                justify-content: center;
            }
        }
        &.waf-overlay-list {
            .article-list {
                grid-template-columns: repeat(4, 1fr);
            }
        }
        .search-result-count {
            border-radius: 0 0 var(--border-radius-l) var(--border-radius-l);
            padding: var(--space-10);
            .label {
                font-size: 3.2rem;
            }
        }
    }
    .waf-listing.waf-horizontal-list {
        @include article-listing(card);
        .article {
            &-list {
                grid-template-columns: repeat(3, 1fr);
            }
            &-content {
                > a {
                    order: unset;
                }
            }
        }
    }
    .media-listing-page {
        .waf-horizontal-list {
            .article-list {
                @include grid(3);
            }
        }
        .waf-head {
            &::before {
                top: 50%;
            }
        }
    }
    .waf-listing {
        &.waf-horizontal-list {
            .article-title {
                -webkit-line-clamp: 4;
                height: 13rem;
            }
        }
        &.waf-overlay-list {
            .article-title {
                -webkit-line-clamp: 4;
                height: auto;
            }
        }
    }
}